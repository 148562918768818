import React from "react";

import { Box, Image, Text, Heading } from "grommet";

const main = () => {
  return (
    <Box>
      <Box direction="row-responsive" justify="center" pad={{ left: "80px" }}>
        <Box
          height="500px"
          width="500px"
          margin={{ top: "20px" }}
          justify="center"
          align="center"
        >
          <Heading level="1">Designer & Developer & UI+UX</Heading>
          <Text margin="30px">Hello , My Name is Mohammad Javad Tavideh</Text>
        </Box>
        <Box
          height="500px"
          width="250px"
          margin={{ top: "20px" }}
          animation={{
            type: "fadeIn",
            delay: 0,
            duration: 6000,
            size: "large",
          }}
        >
          <Image fit="cover" src="../img/ok62.png" />
        </Box>
      </Box>
    </Box>
  );
};

export default main;
