import React from 'react';
import {BrowserRouter}from 'react-router-dom';

import { Box } from 'grommet';
import Layer1 from './components/Layer1';

function App() {
  return (
    <BrowserRouter>
      <Box>
        <Layer1/>
      </Box>
    </BrowserRouter>
  );
};

export default App;
