import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Box } from "grommet";
import Heder from "./Heder";
import Sider from "./Sider";
import Main from "./Main";

const layer = () => {
  return (
    <BrowserRouter>
      <Box>
        <Box>
          <Heder />
        </Box>
        <Box></Box>
        <Box>
          <Box>
            <Sider />
          </Box>
          <Box>
            <Main />
          </Box>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default layer;
