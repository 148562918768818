import React from "react";
import { NavLink } from "react-router-dom";

import { Box, Image } from "grommet";

// const style1 = {
//   position: "fixed",
//   top: "0",
//   width: "100%",
//   fontFamily: "Bison-Bold",
// };

const Heder = () => {
  return (
    <Box 
    justify="center" 
    margin="medium"
    >
      <Box
        margin="10px"
        pad="3px"
        gap="medium"
        //border="all"
        round="small"
        direction="row-responsive"
        elevation="large"
        //width= {{min: "360px" ,max:"900px"}}
        height="75px"
        justify="between"
        align="center"
        // style={style1}
      >
        <Box direction="row" gap="medium">
          <NavLink to="/">HOME</NavLink>
          <NavLink to="/About">ABOUT</NavLink>
          <NavLink to="/Skill">SKILL</NavLink>
          <NavLink to="/Education">EDUCATION</NavLink>
        </Box>
        <Box height="100px" width="100px">
          <Image align="center" fit="cover" src="../img/MJT.png" />
        </Box>
        <Box direction="row" gap="medium">
          <NavLink to="/Work">WORK</NavLink>
          <NavLink to="/Exprience">EXPRIENCE</NavLink>
          <NavLink to="/Contact"></NavLink>
        </Box>
      </Box>
    </Box>
  );
};

export default Heder;
