import React from "react";

import { Box, Sidebar, Button, Nav, Avatar } from "grommet";
import {
  Mail,
  Reactjs,
  Facebook,
  Instagram,
  Twitter,
  Waypoint,
} from "grommet-icons";

const style1 = {
  position: "fixed",
  top:"110px",
  width: "100%",
};

const side = () => {
  return (
    <Box style={style1}>
      <Sidebar
        background="black"
        round="small"
        align="center"
        width="75px"
        margin={{
          //top: "90px",
          left: "xsmall",
        }}
        header={<Avatar src="../img/pic1.jpg" />}
      >
        <Nav>
          <Button icon={<Facebook />} hoverIndicator />
          <Button icon={<Instagram />} hoverIndicator />
          <Button icon={<Twitter />} hoverIndicator />
          <Button icon={<Mail />} hoverIndicator />
          <Button icon={<Waypoint />} hoverIndicator />
          <Button icon={<Reactjs />} hoverIndicator />
        </Nav>
      </Sidebar>
    </Box>
  );
};

export default side;
